import React, {Fragment} from "react"


class Cover extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
    if(this.props.onClick == undefined){
        return(
        <div className={"cover " + this.props.option + (this.props.full ? ' full' : '')}>
            {this.props.children}
        </div>
        )
    }else{
    return(
        <div onClick={this.props.onClick != undefined ? () => this.props.onClick() : {} } className={"cover " + this.props.option + (this.props.full ? ' full' : '')}>
            {this.props.children}
        </div>
        )
    }
    }
}

export default Cover;

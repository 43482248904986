import React, {Fragment} from "react"

import Cover from './cover'
import '../../scss/cover-picker.scss'

class CoverPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            coverOption: 'bg bg-1',
            coverHelper: null,
        };
    }

    handleOpen(){
        if(this.state.modalOpen !== true){
            this.setState({
                modalOpen: true,
                coverHelper: this.state.coverOption
            })
        }
    }

    handleClose(){
        this.setState({
            modalOpen: false,
        })
    }

    handlerOptionSelection(option){
        this.setState({
            coverHelper: option,
        })
    }

    handleApply(){
        this.props.onChange(this.state.coverHelper);
        this.setState({
            coverOption: this.state.coverHelper,
            modalOpen: false
        })
    }

    render(){
    return(
    <div className={this.state.modalOpen ? "cover--picker open" : "cover--picker"}>
        <Cover full onClick={() => this.handleOpen()} option={this.state.coverOption}/>
        {this.state.modalOpen &&
        <div className="modal">
            <div className="modal--content">
                <div className="content--wrapper">
                <div className="preview">
                <Cover option={this.state.coverHelper}>
                    <button className="close--click" onClick={() => this.handleClose()}>X</button>
                </Cover>
                </div>
                <p className="selector--title">Elegí un fondo personalizado</p>
                <div className="cover--selector bg">
                    <div className="option cover bg bg-1 full" onClick={() => this.handlerOptionSelection('bg bg-1')}/>
                    <div className="option cover bg bg-2 full" onClick={() => this.handlerOptionSelection('bg bg-2')}/>
                    <div className="option cover bg bg-3 full" onClick={() => this.handlerOptionSelection('bg bg-3')}/>
                    <div className="option cover bg bg-4 full" onClick={() => this.handlerOptionSelection('bg bg-4')}/>
                </div>
                <p className="selector--title">O podés elegir un color</p>
                <div className="cover--selector color">
                    <div className="option color cover color-1 full" onClick={() => this.handlerOptionSelection('color color-1')}/>
                    <div className="option color cover color-2 full" onClick={() => this.handlerOptionSelection('color color-2')}/>
                    <div className="option color cover color-3 full" onClick={() => this.handlerOptionSelection('color color-3')}/>
                    <div className="option color cover color-4 full" onClick={() => this.handlerOptionSelection('color color-4')}/>
                    <div className="option color cover color-5 full" onClick={() => this.handlerOptionSelection('color color-5')}/>
                    <div className="option color cover color-6 full" onClick={() => this.handlerOptionSelection('color color-6')}/>
                </div>
                </div>
                <button className="button--global" onClick={() => this.handleApply()}>Aplicar</button>
            </div>
        </div>
        }
    </div>
    );
    }
}

export default CoverPicker;

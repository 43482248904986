import React, {Fragment} from "react"


import '../../scss/switcher.scss'



class Switcher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {position: 'start'};
    }

    select(position){
        this.setState({
            position: position,
        })
    }

    render(){
    return(
    <div className="switcher--container">
    <div className="option--list">
        <p onClick={() => this.select('start')} className="option option-1">{this.props.options[0]}</p>
        <p onClick={() => this.select('center')} className="option option-2">{this.props.options[1]}</p>
        <p onClick={() => this.select('end')} className="option option-3">{this.props.options[2]}</p>
    </div>
    <div className="multiswitch">
        <div onClick={() => this.select('start')} className="switcher pin start"/>
        <div onClick={() => this.select('center')} className="switcher pin center"/>
        <div onClick={() => this.select('end')} className="switcher pin end"/>
        <div className={"switcher "+ this.state.position}/>
    </div>
    </div>
    );
    }
}

export default Switcher;

import React from "react"

import WOW from 'wowjs';
import { Helmet } from "react-helmet"
import { navigate } from "gatsby"
import { findDOMNode } from "react-dom";
import * as yup from 'yup';

import { hashString } from "react-hash-string";

import { Link } from "gatsby";

import Topbar from '../components/topbar'
import SelectableList from '../components/selectableList'
import Switcher from '../components/switcher'
import CoverPicker from '../components/coverPicker'
import { APP_URI } from '../configs';
import { Dropdown, Checkbox } from 'semantic-ui-react'

import ImageUploading from 'react-images-uploading';

import ilustration from '../images/ilustration.jpg'
import phone from '../images/device-phone.svg'
import desktop from '../images/device-desktop.svg'
import consola from '../images/device-console.svg'

import selectImage from '../images/select-image.svg'

import {register, register2, userExist, getUser} from '../userHelper.js'
import UserContext from "../userContext.js"


import '../scss/signup.scss'
import 'semantic-ui-css/semantic.min.css'

export default class login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      signUpTitle: 'Creá tu cuenta',
      buttonText: 'Siguiente',
      step: 1,
      day: null,
      month: null,
      year: null,

      errors:[],

      username: null,
      email: null,
      password: null,

      cover: 'bg bg-1'

    };
    this.references = {
      hero: React.createRef(),
      services: React.createRef(),
      numbers: React.createRef(),
      features: React.createRef(),
      logos: React.createRef(),
      contact: React.createRef(),
    }
  }


  //STEPPER

  handleNext = async(user) => {


    switch(this.state.step){
      case(1):{
        var errors = []
        var email = this.state.email;
        if(!(email != null && email.match(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/))){
          errors.push('email')
        }
        var password = this.state.password;
        if(!(password != null && password.length > 3 && password.length < 35 && !password.includes(' '))){
          errors.push('password')
        }  
        if(errors.length === 0){
          const hash = hashString(this.state.email+this.state.password);
          await getUser(hash).then(newuser => {
            console.log('newuser', newuser)
            if(!newuser){
              navigate('/login')
            } else {
              //user.updateUser(newuser);
              console.log('user', user)
              user.updateUser(newuser)
              navigate('/feed')
              // navigate('/user')
            }
          });
        } else {
          this.setState({errors: errors})
        }
        break;
      }

    }
  }

  handleBack = (e) => {
      navigate("/")

  }

  handleCoverChange = (cover) => {
    this.setState({cover})
  }

  //image
  handleImageUpload = (imageList, addUpdateIndex) => {
      this.setState({
        images: imageList
      })
  }

  // DATE PICKER

  days = () => {
    let days = [];

    for (var i = 1; i <= 31; i++) {
      days.push({key: i, text: i.toString(), value: i});
   }
   return days;
  }

  years = () => {
    let days = [];

    for (var i = 1921; i <= 2021; i++) {
      days.push({key: i, text: i.toString(), value: i});
   }
   return days;
  }

  available_months = () => {

    return [      
    {key: '1', value:'1', text:"Enero"},
    {key: '2', value:'2', text:'Febrero'},
    {key: '3', value:'3', text:'Marzo'},
    {key: '4', value:'4', text:'Abril'},
    {key: '5', value:'5', text:'Mayo'},
    {key: '6', value:'6', text:'Junio'},
    {key: '7', value:'7', text:'Julio'},
    {key: '8', value:'8', text:'Agosto'},
    {key: '9', value:'9', text:'Septiembre'},
    {key: '10', value:'10', text:'Octubre'},
    {key: '11', value:'11', text:'Noviembre'},
    {key: '12', value:'12', text:'Diciembre'}]
  }

  handleDayChange = (e, { value }) => {
    this.setState({day: value})
  }

  handleMonthChange = (e, { value }) => {
    this.setState({month: value})
  }

  handleYearChange = (e, { value }) => {
    this.setState({year: value})
  }

  render(){
    return(
    <div  className="signup" >
      <Helmet>

      </Helmet>


      <UserContext.Consumer>
      {userObject => (
       <section ref={this.references.features} className="signup--section">
         <div className="wrapper">
            <div className="signup--box">
            <div className="back--container">
                <p onClick={() => this.handleBack()}>Volver atras</p>
              </div>
              {this.state.step === 1 &&
                <div className="step step-1">
                  <h2 className="signup--title">¡Hola Trooper!</h2>
                  <p className="signup--subtitle">Ingresá tus datos para acceder.  </p>
                  <p className="input--title">Correo electrónico *</p>
                  <div className={`ui input ${this.state.errors.includes('email') ? 'error' : ''}`}><input type="email" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} placeholder=""/></div>
                  {this.state.errors.includes('email') && <div className="error--message"><p>Entrada invalida</p></div>}
                  <p className="input--title">Contraseña *</p>
                  <div className={`ui input ${this.state.errors.includes('password') ? 'error' : ''}`}><input type="password" value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} placeholder=""/></div>
                  {this.state.errors.includes('password') && <div className="error--message"><p>Entrada invalida</p></div>}
                </div>
              }
              <button className="button--global" onClick={() => this.handleNext(userObject)}>{this.state.buttonText}</button>
              <p className="signup--link">¿No tenés una cuenta? <Link to="/signup">Registrate.</Link></p>
            </div>
          </div>
      </section>
      )}
      </UserContext.Consumer>
    </div>
    );
  }
}
